<template>
	<el-form v-loading='loading'>
		<div style="overflow-x:auto;">
			<div class="filter-container" style="background: #fff;padding: 15px;">
				<div class="filter-item">
					<el-input v-model="keyWords" @keyup.enter.native="goodsSearch" placeholder="请输入关键字"
						style="width: 250px;;margin-right: 10px;" clearable></el-input>
					<el-button type="primary" @click="goodsSearch">查询</el-button>
				</div>
				<!-- 商品 -->
				<div class="supply-goods">
					<div class="category">
						<label class="label">所有类目: </label>
						<el-checkbox-group v-model="checkList" @change="handleCheckedChange">
							<el-checkbox v-for="item in categoryList" :label="item.Id" :key="item.Id"
								style="margin:0px 40px 10px 0px">{{item.CategoryName}}</el-checkbox>
						</el-checkbox-group>
						<div style="margin-top:10px" v-if="shopclassitylist&&shopclassitylist.length">
							<label class="label">商品分类: </label>
							<el-checkbox-group v-model="checkshopList" @change="handleCheckedChange">
								<el-checkbox :label="null" key="null" style="margin:0px 40px 10px 0px">全部</el-checkbox>
								<el-checkbox v-for="item in shopclassitylist" :label="item.Id" :key="item.Id"
									style="margin:0px 40px 10px 0px">{{item.CategoryName}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="sort-container">
						<div class="sort-nav" :class="{'active-sort':currentSort=='SellCountAll'}"
							@click="handleChangeSort('SellCountAll')">销量</div>
						<div class="sort-nav" :class="{'active-sort':currentSort=='ProfitRate'}"
							@click="handleChangeSort('ProfitRate')">利润</div>
						<div class="sort-nav" :class="{'active-sort':currentSort=='SupplierMallPrice'}"
							@click="handleChangeSort('SupplierMallPrice')">供货价</div>
						<div class="sort-nav" :class="{'active-sort':currentSort=='SupplierProductLastReleaseTime'}"
							@click="handleChangeSort('SupplierProductLastReleaseTime')">最新上架</div>
					</div>
					<div class="sort-input">
						<div style="display:inline-block;">
							<span>供货价：￥</span>
							<el-input v-model="StartSupplierMallPrice" style="width:100px;"
								@input='StartSupplierMallPrice=onlyNumber(StartSupplierMallPrice)'></el-input>
							<span style="margin:0 5px;">~</span>
							<span>￥</span>
							<el-input v-model="EndSupplierMallPrice" style="width:100px;"
								@input='EndSupplierMallPrice=onlyNumber(EndSupplierMallPrice)'></el-input>
						</div>
						<div style="display:inline-block;margin:0 20px;">
							<span>利润率：</span>
							<el-input v-model="StartProfitRate" style="width:100px;"
								@input='StartProfitRate=onlyNumber(StartProfitRate)'></el-input>
							<span>%</span>
							<span style="margin:0 5px;">~</span>
							<el-input v-model="EndProfitRate" style="width:100px;"
								@input='EndProfitRate=onlyNumber(EndProfitRate)'></el-input>
							<span>%</span>
						</div>
						<el-button type="primary" style="margin:0 10px;" @click="goodsSearch">确定</el-button>
						<el-button type="primary" @click="clearInput">清除</el-button>
					</div>
					<div class="table-container" style="margin-top:20px">
						<div class="goods-list">
							<div class="goods-item" v-for="(item,index) in dataList" :key='index'>
								<div class="item-img" @click="openDetailePage(item)">
									<img style="width:160px;height:160px;object-fit:cover;" :src='imgUrl+item.ImgUrl '>
									<div class="img-mask">
										<span style="margin-right:10px;">销量</span>
										<span
											v-if='item.SellCountAll >100000||item.SellCountAll ==100000'>{{Math.floor(item.SellCountAll /10000)}}万+件</span>
										<span v-else>{{item.SellCountAll }}件 </span>
									</div>
								</div>
								<div class="item-name">{{item.Name}}</div>
								<div class="item-price">供货价：￥{{item.SupplierMallPrice}} </div>
								<div class="item-price">库存：{{item.Stock}} </div>
								<div class="item-price">
									利润：
									<span style="color:#F56C6C;">{{item.ProfitRate}}%</span>
								</div>
								<div class="font-hidden" style="margin-bottom:5px">供货商：{{item.MallName}} </div>
								<div class="item-button" v-if='!item.IsAddedToMall'>
									<div class="button-left" @click="handleAddWarehouse(item.MallId,item.Id)">添加到仓库
									</div>
									<div class="button-right" @click="handleAddMarket(item.MallId,item.Id)">上架到商城</div>
								</div>
								<div class="item-button" v-else>已添加到商城 </div>
							</div>
						</div>
						<div v-if='!page.total' style='line-height: 60px;color: #909399;text-align:center;width:100%;'>
							暂无数据</div>
					</div>
				</div>

				<div style="text-align: right;">
					<el-pagination v-if="page.total" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" :current-page="page.current"
						:page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
						layout="total, sizes, prev, pager, next, jumper" :total="page.total">
					</el-pagination>
				</div>
			</div>
		</div>
	</el-form>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import {
		productCategoryLibraryList,
		supplierProductCategoryfilterBoxList
	} from '@/api/goods'
	export default {
		data() {
			return {
				loading: false,
				keyWords: '',
				StartSupplierMallPrice: null,
				EndSupplierMallPrice: null,
				StartProfitRate: null,
				EndProfitRate: null,
				dataList: [],
				dataList2: [],
				page: {
					total: 0,
					current: 1,
					size: 40
				},
				categoryList: [],
				checkList: [],
				imgUrl: config.IMG_BASE,
				currentSort: 'SellCountAll',
				shopclassitylist: [],
				checkshopList: [],
			}
		},
		created() {
			this.keyWords = this.$route.query.keyWords ? this.$route.query.keyWords : ''
			this.getCategory()
			this.getList()
		},
		methods: {
			onlyNumber(obj) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1');
				obj = obj.replace(/[^\d.]/g, "");
				obj = obj.replace(/\.{2,}/g, ".");
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
				if (obj.indexOf(".") < 0 && obj != "") {
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				return obj
			},
			keyTypeChange() {
				this.keyWords = ''
				this.page.current = 1
			},
			//获取数据
			async getList() {
				this.loading = true
				let IsAsc = this.currentSort == 'SupplierMallPrice' ? true : false
				try {
					let data = {
						Keywords: this.keyWords,
						CategoryIdList: this.checkList,
						SupplierProductCategoryIdList: this.checkshopList.includes(null) ? [] : this.checkshopList,
						StartSupplierMallPrice: this.StartSupplierMallPrice,
						EndSupplierMallPrice: this.EndSupplierMallPrice,
						StartProfitRate: this.StartProfitRate,
						EndProfitRate: this.EndProfitRate,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						OrderBy: this.currentSort,
						IsAsc: IsAsc
					}
					let result = await apiList.supplierMarketProductList(data)
					this.page.total = result.Result.Total;
					this.dataList = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			async getCategory() {
				let result = await productCategoryLibraryList()
				this.categoryList = result.Result
				let res = await supplierProductCategoryfilterBoxList()
				this.shopclassitylist = res.Result
			},
			handleCheckedChange() {
				this.getList()
			},
			goodsSearch() {
				this.page.current = 1;
				this.getList();
			},
			clearInput() {
				this.StartSupplierMallPrice = null
				this.EndSupplierMallPrice = null
				this.StartProfitRate = null
				this.EndProfitRate = null

				this.page.current = 1;
				this.getList()
			},
			handleChangeSort(v) {
				this.currentSort = v
				this.page.current = 1
				this.getList()
			},
			async handleAddWarehouse(MallId, Id) {
				this.loading = true
				try {
					let result = await apiList.supplierMarketProductSave({
						SupplierMallId: MallId,
						SupplierProductId: Id,
						IsOpen: false
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						})
						this.getList()
					} else {
						this.loading = false
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			async handleAddMarket(MallId, Id) {
				this.loading = true
				try {
					let result = await apiList.supplierMarketProductSave({
						SupplierMallId: MallId,
						SupplierProductId: Id,
						IsOpen: true
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						})
						this.getList()
					} else {
						this.loading = false
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			openDetailePage(val) {
				this.$router.push({
					path: 'supplyDetail',
					query: {
						SupplierMallId: val.MallId,
						SupplierProductId: val.Id
					}
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.filter-container {
		// width: 1660px;
		margin: 0 auto;
	}

	.category {
		padding: 10px;
		border: 1px solid #ddd;
		margin-bottom: 20px;
	}

	.sort-container {
		background: #eee;
		border: 1px solid #ddd;
		height: 32px;
	}

	.sort-input {
		background: #eee;
		border: 1px solid #ddd;
		line-height: 50px;
		padding: 0 10px;
	}

	.sort-nav {
		cursor: pointer;
		display: inline-block;
		line-height: 30px;
		text-align: center;
		width: 100px;
	}

	.table-container {
		margin-top: 20px;
	}

	.active-sort {
		background-color: #fff;
	}

	.goods-list {
		
		display: flex;
		flex-wrap: wrap;
		
		.goods-item {
			font-size: 12px;
			width: 160px;
			overflow: hidden;
			margin-right: 50px;
			margin-bottom: 30px;

			.item-img {
				cursor: pointer;
				width: 160px;
				height: 160px;
				position: relative;
				text-align: center;

				.img-mask {
					color: #fff;
					bottom: 0;
					position: absolute;
					width: 100%;
					line-height: 30px;
					background-color: rgba(0, 0, 0, 0.5)
				}
			}

			.item-name {
				width: 100%;
				height: 26px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
				-webkit-box-orient: vertical;
				margin: 7px 0;
			}

			.item-price {
				margin-bottom: 7px;
				font-size: 12px;
			}

			.item-button {
				width: 100%;
				background-color: #F56C6C;
				line-height: 30px;
				text-align: center;
				color: #fff;

				.button-left {
					cursor: pointer;
					color: #fff;
					width: 50%;
					line-height: 30px;
					display: inline-block;
					text-align: center;
					background-color: #409EFF;
				}

				.button-right {
					cursor: pointer;
					color: #fff;
					width: 50%;
					line-height: 30px;
					display: inline-block;
					text-align: center;
					background-color: #2089F4;
				}
			}
		}

		// .goods-item:nth-child(8n) {
		// 	margin-right: 0px;
		// }
	}

	.supply-market {
		.goods-item:nth-child(6n) {
			margin-right: 0px;
		}

		.supply-container {
			margin: 10px 0;
			border-bottom: 1px solid #ddd;
			display: flex;
			flex-direction: row;

			.supply-merchant {
				background-image: pink;
				width: 370px;
				justify-content: center;
				display: flex;
				flex-direction: column;

				.top {
					height: 64px;
					margin-bottom: 10px;
					display: flex;
					flex-direction: row;
					align-items: center;

					img {
						width: 64px;
						height: 64px;
						object-fit: contain;
					}

					.merchant-name {
						margin-left: 10px;
						width: 200px;
						height: 36px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						overflow: hidden;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				.bottom {
					.on-store {
						display: inline-block;
						margin-right: 10px;
						width: 64px;
						line-height: 20px;
						background-color: #409EFF;
						color: #fff;
						font-size: 14px;
						text-align: center;
					}

					.goods-count {
						display: inline-block;
						font-size: 14px;
						color: #909399;
					}

				}
			}
		}
	}

	.font-hidden {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
